@import url(https://fonts.googleapis.com/earlyaccess/alefhebrew.css);



.dashboard-page{
    height: 100%;
    direction: rtl;
    font-family: "Alef Hebrew", sans-serif;

}
.winesTable{
    font-family: "Alef Hebrew", sans-serif;

}
.header{
    /* position: sticky;
    top: 0; */
}
#table-name{
    /* position: sticky;
   top: 8.2em */
}
#table-header{
    /* position: sticky;
    top: 5em;
   z-index: 1; */
}

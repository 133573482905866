.landing_page{
    background-color: black;
    min-height:100vh;
    display: flex;
    flex-wrap: wrap;
    color:white;
    .banner{
        width:65%;
        height: 100vh;
        position: sticky;
        top:0;
        inset-inline-start: 0;
        background-color:#790505 ;
        display:flex;
        background-color: black;

        img{
            max-width: 100%;
        }
    }
    .main{
        width:30%;
        margin-inline: auto;
        padding:1rem ;
        background-color: black;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

       .logo{
            height: 15vh;
            margin-block-end: 1rem;
       }
        .form{
        }
    
        .loading{
           margin-block: 4rem;
           p{
            font-size: 1.5rem;
           }
        }
    }



    @media  screen and (max-width: 768px){
        .landing_page{
            display: block;
            /* height: auto; */
        }
       
    .banner{
        width:100%;
        height: auto;
        position: relative;
         img{
            max-width: 100%;
            }
        }
        .main{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: center;
            align-items: center;
       
        .form{
           margin-block-end: 2rem;
        }
      
        .loading {
            width:100%;
            align-content: center;  
        }
        
     }   
    }
}
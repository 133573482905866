.footer{
    p{
        font-size: 0.75rem;
    line-height: 0.75rem;
    }
    .branches{
        border: 1px solid white;
        font-size: 1rem;
        font-weight: 700;
        padding: 0.5rem;
        margin-block-start:0.5rem ;
        text-align: center;
        box-sizing: border-box;   
        width: 100%;
    }
    a{
        cursor: pointer;
        margin-inline: 0.1rem;
        text-decoration: none;
        color: inherit;
        img{
            height: 0.75rem;
            margin-inline: 0.2rem;
        }
    }
}
.registrationForm{
    color:white;
    text-align:start;
    font-size: 1rem;
    font-weight: lighter;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap:4px;
    line-height: 1rem;
    margin:1px 0;
    
    h2{
        margin:4px 0;
        text-align: center;
        width: 100%;
    }
    p{
        width:100%;
        margin-block:0;
    }
    label{
        display:block;
        width:100%
    }
    .phone_control{
        direction:ltr;
        display: flex;
        border:1px solid white;  
        select{
             width:30%;
             border: none;
             /* appearance: none; */
             border-radius: 0; 
             border-inline-end: 1px solid black;
             text-align: center;  
         }
         input{
              width:70%;
              border: none;
              border-inline-start: 1px solid black;
              position: relative;
          }
    }
          
    .halfWidth{
        width:48%;
    }
    .fullWidth{
        width:100%;
    }
    input{
        box-sizing: border-box;
        width:100%;
        line-height:1rem;
        padding-block: 4px;
        border-radius: 0%;
        border:1px solid white;   
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    [list]::-webkit-calendar-picker-indicator {
        color:transparent
      }
    input:disabled{
        background-color:black;
        color:white;
        font-weight: lighter;
        margin-bottom: 10px;
    }
    .checkboxArea input{
        width: auto;
        margin: 0 0 0 4px;
    }
    .checkboxArea{
        margin: 4px auto ;
    }
    .checkboxArea a{
        margin: 0 4px;
        border:none;
        background-color: transparent;
        color:rgb(215, 11, 11);
        padding: 0;
        font-size: 1rem;
        font-weight: lighter;
        cursor: pointer;
        text-decoration: underline;
    }
    .btn{
        width:100%;
        display:flex;
        flex-wrap: wrap;
        justify-content:space-between;
        gap: 4px;
    }
    .btn div{
        display: inline;
        width:48%;
    }
    .btn button[type=button]{
        background-color: inherit;
        color: inherit;
        border:1px solid white;
        border-radius: 4px;
        width:100%;
        font-size: 1rem;
        padding: 4px 0;
        cursor: pointer;
    }
    .btn button[type=submit]{
        background-color: rgb(215, 11, 11);
        border:none;
        border-radius: 5px;
        width:100%;
        font-size: 1rem;
        padding: 4px 0;
        cursor: pointer;
        box-sizing: content-box;
    }
    span{
        display: block;
        color:rgb(215, 11, 11);
        font-size: 0.75rem;
        line-height: 0.75rem;
        min-height: 0.75rem;;
     }
     #isAgreeTerms{
        position: relative;
        width: 0.75rem;
        height: 0.75rem;
        outline: none;
        border-radius: 3px;
        cursor: pointer;
    } 
    #isAgreeTerms:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0.75rem;
        height: 0.75rem;
        background-color: #555;
        opacity: 0.75;
        transition: opacity 0.2s ease-in-out;
    }
    #isAgreeTerms:checked:before {
        opacity: 0;
    }
    
    @media  screen and (max-width: 768px){
        form{
            font-size: 1rem;
            margin: 2rem 2rem;
            min-height: 100vh; 
        }
        
        input{
            padding-block: 0;
            
        }
    }
}
.terms{
   direction: rtl; 
        ol {
            counter-reset: item;
                li {
                display: table;
                counter-increment: item;
                    &:before {
                        content: counters(item, ".") ". ";
                        display: table-cell;
                        padding: 0.5rem;
                    
                    }
                    li {
                    margin: 0.25rem;
                    }
                }
               
                
        }
a{
    cursor: pointer;
    text-decoration: underline;
    margin-inline-start: 0.25rem;
    color:#1976d2;
}
        @media  screen and (max-width: 768px){
            ol{
                padding: 0;

                li {
                    &:before {
                        padding:0;
                    } 
                }
            }   
        }
}
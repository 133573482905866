#msg{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    color: white;
    padding-block: 1rem;
    h2{
        font-size: 2rem;
    }
    p{
        font-size: 1.5rem;
        margin-block:0.5rem;
        width: 100%;
    }
}